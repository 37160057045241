<template>
  <div class="px-4 md:px-0">
    <div class="card mt-8 md:mt-10 mb-10" v-for="question in questions" :key="question.external_id">

      <h2 class="font-black mb-3 text-xl md:text-1xl leading-6 md:leading-7">{{ question.question }}</h2>
      <div
        class="flex mb-5 last:mb-0 flex-wrap"
        v-for="answer in question.answers"
        :key="answer.external_id"
      >
        <div class="flex w-full items-center">
          <input
            type="radio"
            :value="answer.answer_id"
            v-model="question.answer"
            class="sm mr-4 border-gray-border border rounded-sm"
            :name="question.external_id"
            :id="answer.answer_id"
          />
          <label :for="answer.answer_id" class="font-medium">{{ answer.answer }}</label>
        </div>

        <div v-if="answer.has_text_input && question.answer === answer.answer_id" class="w-full mt-4">
          <input type="text" v-model="question.text_answer" class="w-12/12 md:w-6/12" />
        </div>

      </div>
    </div>

    <div class="flex flex-wrap items-start md:flex-nowrap mb-4">
      <div class="w-full md:w-3/6 order-2 md:order-1">
        <PreviousStepButton
          :id="this.$options.name + '-prev'"
          :styling="'btn btn--secondary-cta w-full md:flex-1 md:mr-2'"
          :button-text="t('GeneralV2', 'go_back')">
        </PreviousStepButton>
      </div>

      <div class="w-full md:w-3/6 order-1 md:order-2">
        <MyButton
          v-if="isOnLastStep"
          :id="this.$options.name + '-cancel'"
          :styling="'btn btn--cancel-cta w-full md:flex-1 mb-4 md:mb-0 md:ml-2'"
          :buttonText="buttonText"
          :loading="loading"
          :disabled="subscriptionCancelledError"
          v-on:click="doCancel"></MyButton>
        <MyButton
          v-else
          :id="this.$options.name + '-next'"
          :styling="'btn btn--primary-cta w-full md:flex-1 mb-4 md:mb-0 md:ml-2'"
          :buttonText="buttonText"
          :loading="loading"
          v-on:click="goNext"></MyButton>
        </div>
    </div>


    <p v-if="subscriptionCancelledError" class="text-red-700 mt-4">
      {{ t('ConfirmationV2', 'error') }}
    </p>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex';
import { translate } from '../store/helpers';
import { gtm } from '../utils/gtm';
import MyButton from "./Button";
import PreviousStepButton from "./PreviousStepButton";

export default {
  name: 'questionnaire-component',
  components: {
    MyButton,
    PreviousStepButton,
  },
  mounted() {
    this.fetchIsTest();
    this.fetchSubNr();
  },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
    async goNext() {
      await this.sendQuestionnaireData();
      this.nextStep();
    },
    async doCancel() {
      await this.sendQuestionnaireData();
      await this.cancelSubscription(false);

      if (this.subscriptionCancelledSuccess) {
        this.nextStep();
      }
    },
    async sendQuestionnaireData() {
      const data = this.questions.map((question) => ({
        event:'questionAnswer',
        questionAnswerNo: question?.answer,
        current_subscription: this.subscription?.subscriptionNumber,
      }));

      data.forEach((d) => {
        gtm.push(d);
      });

      this.questions.forEach((q) => {
        if (typeof q.text_answer !== 'undefined' && q.text_answer.length > 0) {
          this.sendOtherAnswer({
            'ReasonCode': q?.answer,
            'Value': q?.text_answer,
            'ProductCode': this.subscription?.productCode,
          });
        }
      });
    },
    sendOtherAnswer(data) {
      const postData = new FormData();
      postData.append('ReasonCode', data.ReasonCode);
      postData.append('Value', data.Value);
      postData.append('ProductCode', data.ProductCode);

      window.axios
      .post(
        `/wp/wp-admin/admin-ajax.php?action=send_questionnaire_data`,
        postData
      )
    },
    ...mapActions({
      cancelSubscription: 'confirmationModule/cancelSubscription',
      fetchIsTest: 'confirmationModule/fetchIsTest',
      fetchSubNr: 'confirmationModule/fetchSubNr',
    }),
    ...mapActions({
      nextStep: 'nextStep'
    }),
  },

  computed: {
    ...mapGetters(['currentStepData', 'subscription', 'isOnLastStep', 'isTest']),
    ...mapState({
      loading: state => state.confirmationModule.subscriptionCancelLoading,
      subscriptionCancelledError: state => state.confirmationModule.subscriptionCancelledError,
      subscriptionCancelledSuccess: state => state.confirmationModule.subscriptionCancelledSuccess,
    }),
    hasAnswers() {
      return this.questions.filter((q) => {
        return typeof q.answer !== 'undefined';
      }).length > 0;
    },
    buttonText() {
      if (this.isOnLastStep) {
        return this.t('GeneralV2', 'cancel_subscription_now');
      }
      return this.hasAnswers ? this.t('GeneralV2', 'continue') : this.t('GeneralV2', 'continue_without_answer');
    },
    questions() {
      return this.currentStepData && this.currentStepData.object;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
