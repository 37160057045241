<template>
  <div>
    <div class="mt-10 mb-2">

      <Offers
        class="mb-10"
        :store-module="'extensionModule'"
        :cover="extension.cover"
        :should-hide-first-bullet="parseInt(extension.bmd_campaign_id, 10) < 366167"
        :offers="offers">
      </Offers>

      <div class="flex flex-wrap items-start md:flex-nowrap text-lg">
        <div class="w-full md:w-3/6 order-2 md:order-1">
          <PreviousStepButton
            :id="this.$options.name + '-prev'"
            :styling="'btn btn--secondary-cta w-full md:flex-1 md:mr-2'"
            :button-text="t('GeneralV2', 'go_back')">
          </PreviousStepButton>
        </div>

        <div class="w-full md:w-3/6 order-1 md:order-2">
          <CancelButton
            v-if="wantToCancel && isOnLastStep"
            :id="this.$options.name + '-cancel'"
            :styling="'btn btn--cancel-cta mb-4 md:mb-0 w-full md:ml-2'"
            :wrapper-styling="'flex-100 md:flex-1'"
            :buttonText="t('GeneralV2', 'cancel_subscription_now')">
          </CancelButton>

          <NextStepButton
            v-else-if="wantToCancel && !isOnLastStep"
            :id="this.$options.name + '-next'"
            :styling="'btn btn--primary-cta w-full md:flex-1 mb-4 md:mb-0 md:ml-2'"
            :buttonText="t('general', 'continue')"
          >
          </NextStepButton>

          <NextExtension
            v-else
            :id="this.$options.name + '-accept'"
            :styling="'btn btn--primary-cta w-full md:flex-1 mb-4 md:mb-0 md:ml-2'"
            :disabled="!hasSelectedOffer"
            :button-text="t('OffersV2', 'confirm')">
          </NextExtension>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import NextExtension from './NextExtension';
import PreviousStepButton from "../PreviousStepButton";
import NextStepButton from "../NextStepButton";
import CancelButton from "../CancelButton";
import Offers from '../Offers';
import { translate } from '../../store/helpers';

export default {
  name: 'extend-offers',
  components: {
    NextExtension,
    PreviousStepButton,
    NextStepButton,
    CancelButton,
    Offers,
  },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters(['isOnLastStep']),
    ...mapState('extensionModule', ['extension', 'selectedOffer']),
    hasSelectedOffer() {
      return this.selectedOffer !== null;
    },
    wantToCancel() {
      return this.selectedOffer !== null && parseInt(this.selectedOffer.id, 10) === -1;
    },
    offers() {
      if (!this.extension?.offers) {
        return [];
      }

      return this.extension.offers.concat([{
          id: -1,
          rebateLabelV2: this.t('OffersV2', 'cancel_offer'),
        }]);
    },
  }
};
</script>

<style scoped></style>
