<template>
  <header class="w-full">
    <div class="w-full bg-white px-3 border-t border-b border-gray-darkborder shadow-inner">
      <div class="container relative flex items-center justify-center mx-auto h-[74px] md:h-[106px]">
        <a href="#" v-on:click.prevent="onBackClick" class="absolute left-0 hidden md:block text-bp-blue font-black uppercase">
          <i class="fas fa-chevron-left mr-2"></i>
          {{t('general','to_customer_service')}}
        </a>
        <img :src="logoUrl" class="logo" />
      </div>
    </div>

    <div
      class="bg-center bg-cover w-full flex items-center md:items-end justify-center md:pb-4 relative"
      :style="styleObj"
      v-if="shouldShow"
    >
      <transition name="slide-fade-header" mode="out-in">
        <h2
          :key="headerText"
          class="text-white text-center"
        >
          {{ headerText }}
        </h2>
      </transition>
    </div>
  </header>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import { translate } from '../store/helpers';

export default {
  computed: {
    ...mapGetters([
      'headerImage',
      'headerImageMobile',
      'headerText',
      'currentStep',
      'isV2'
    ]),

    ...mapState({
      logoUrlDesktop: (state) => state.logoUrl,
      logoUrlMobile: (state) => state.logoUrlMobile,
      crossSellCampaigns: (state) => state.crossSellModule.crossSellCampaigns,
    }),
    styleObj() {
      return {
          backgroundImage: window.innerWidth > 768 ||  this.headerImageMobile.length === 0 ? `url(${this.headerImage})` : `url(${this.headerImageMobile})`,
          height: window.innerWidth > 768 ? '260px' : '80px',
        };
    },
    shouldShow() {
      return this.currentStep !== 'finished' || this.isV2;
    },
    logoUrl() {
      return window.innerWidth > 768 ? this.logoUrlDesktop : this.logoUrlMobile;
    }
  },
  methods: {
    onBackClick() {
      this.pushInteraction({
        event: 'interaction click',
        eventParams: { clickedText: this.t('general','to_customer_service')},
        engagement: {
          type: 'click',
          component: {
            name: 'absolute left-0 hidden md:block text-bp-blue font-black uppercase',
            type: 'absolute left-0 hidden md:block text-bp-blue font-black uppercase',
          },
        },
      });
      window.location.href = '/';
    },
    t(component, key) {
      return translate(component, key);
    },
    ...mapActions({
      pushInteraction: 'pushInteraction',
    }),
  },
};
</script>

<style scoped lang="scss">
.logo {
  max-height: 100%;
}

h1 {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
