<template>
    <button :class="styling" v-if="loading" :id="id">
      <div class="sk-circle sk-circle--sm">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
      </div>
    </button>

    <button :disabled="disabled" v-else :class="styling" v-on:click="click" :id="id">
      {{buttonText}}
    </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'button-component',
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    styling: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
  computed: {
    ...mapGetters([
      'secondaryColor',
      'primaryColor'
    ]),
  },
};
</script>

<style scoped lang="scss">
  .sk-child:before {
    background-color: #B4B3B4;
  }
</style>
