<template>
  <MyButton
    v-on:click="buyNow"
    :class="styling"
    :loading="!purchaseLoaded"
    :buttonText="buttonText" :id="'extend-offers-accept'"></MyButton>
</template>

<script>
  import MyButton from "../Button";
  import {mapGetters, mapState} from 'vuex';
  import {gtm} from "@/apps/saveflowV2/utils/gtm";

  export default {
    name: 'one-click-extend',
    components: {MyButton},
    data() {
      return {
        paymentType: 'recurring',
        selectedPaymentOption: '',
        action: '',
        password: '',
        paymentOptionsLoaded: false,
        purchaseLoaded: true,
        paymentOptions: [],
      };
    },
    props: {
      buttonText: {
        type: String,
        default: ''
      },
      styling: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      checkoutEndpointAction: {
        type: String,
        default: 'order_extension'
      },
    },
    computed: {
      ...mapGetters([
        'currentStepData',
        'activePaymentMethod',
        'currentUrl',
        'isTest',
        'testZip',
      ]),
      ...mapGetters('extensionModule',[
        'getIndex',
      ]),
      ...mapState('extensionModule', [
        'selectedOffer',
        'extension',
      ]),
      offers() {
        if (!this.extension?.offers) {
          return [];
        }
        return this.extension.offers;
      },
    },
    watch: {
      paymentOptionsLoaded(newValue) {
          if (newValue)  {
            gtm.selectPaymentOption(this.activePaymentMethod.paymentTypeApi, true);
            this.selectedPaymentOption = this.paymentOptions[0].value;
          }
      },
      selectedPaymentOption(newValue, oldValue) {
        gtm.selectPaymentOption('Recurring', oldValue === '');
        gtm.pushAddPaymentInfo([this.selectedOffer.newDataLayer])
      },
    },
    mounted() {
      gtm.initCheckout(this.offers[0].dataLayer);
      gtm.pushBeginCheckout(this.offers[0].dataLayer);
      this.fetchPaymentOptions();

      this.$store.dispatch(
        `extensionModule/setSelectedOffer`,
        this.offers[0],
      );
    },
    methods: {
      fetchPaymentOptions() {
        return window.axios
          .get('/wp/wp-admin/admin-ajax.php', {
            params: {
              campaign_id: this.extension.external_id,
              recurring: true,
              action: 'get_payment_options',
            }
          })
          .then(response => {
            this.paymentOptions = response.data;
            this.paymentOptionsLoaded = true;
          });
      },
      async buyNow() {
        this.purchaseLoaded = false;

        if (!this.paymentOptionsLoaded) {
          await this.fetchPaymentOptions();
        }

        const data = new FormData();
        data.append('campaign_id', this.extension.external_id);
        data.append('offer_id', this.selectedOffer.id);
        data.append('payment_option', this.selectedPaymentOption);
        data.append('payment_option_type', this.activePaymentMethod.paymentTypeApi === 'mobilePayment' ? 'MPS' : 'WEBPAY');

        if (this.currentUrl) {
          data.append('cancel_url', this.currentUrl);
        }
        if (this.extension.is_digital_subscriber_campaign) {
          data.append('password', this.password);
        }
        data.append('checkout_page_url', window.location.href);
        data.append('is_test', this.isTest);
        if (this.testZip.length) {
          data.append('test_zip', this.testZip);
        }
        gtm.clickPurchaseButton();
        gtm.pushGoToPayment([this.selectedOffer.newDataLayer])

        window.axios
          .post(
            `/wp/wp-admin/admin-ajax.php?action=${this.checkoutEndpointAction}`,
            data
          )
          .then(response => {
            this.action = response.data.url;
            this.purchaseLoaded = true;
            // Redirect to payment
            window.location.href = this.action;
          });
      }
    },
  }
</script>

<style scoped>

</style>
