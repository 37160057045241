<template>
  <div class="card mb-6 -mt-2">
    <h3 class="font-black mb-4" v-html="currentStepData.heading" />

    <div class="newsletter-content">
      <p class="text-base font-medium leading-sm">
          {{ currentStepData.text }}
          <span class="read-more-wrapper">
							<a href="#" class="read-more-toggle underline" v-on:click.prevent="toggleContent" v-if="!showContent && currentStepData.expanded_text.length">{{ currentStepData.expanded_text_toggle }}</a>
							<span class="read-more-content" v-html="currentStepData.expanded_text" v-if="showContent"></span>
          </span>
        {{ currentStepData.policy_text }}
        <a :href="currentStepData.privacyPolicyUrl" class="underline" target="_blank">{{ currentStepData.policy_link_text }}</a>
      </p>
    </div>

    <div class="flex mt-4" v-if="!success">
      <input
        type="text"
        v-model="newsletterEmail"
        class="w-64 mr-4 self-start"
      />

      <div class="w-3/6">
        <MyButton
          class="block"
          :id="this.$options.name + '-accept'"
          :styling="'btn btn--secondary-cta w-full'"
          :loading="loading"
          :buttonText="buttonText"
          v-on:click="doAction">
        </MyButton>
      </div>
    </div>

    <p
      v-if="invalidEmail"
      class="block mt-5 mb-4 text-red-danger text-md">
      <i class="fas fa-exclamation-triangle mr-2"></i>
      {{t('Newsletterv2', 'mail_error')}}</p>
    <Alert v-else-if="error" class="my-4" :alert-type="'error'" :alert-content="t('Newsletterv2', 'error')"></Alert>
    <Alert v-else-if="success" class="my-4" :alert-type="'success'" :alert-content="t('Newsletterv2', 'success')"></Alert>

  </div>
</template>

<script>
import MyButton from './Button';
import Alert from './Alert';
import { translate } from '../store/helpers';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'newsletter-component',
  components: {
    Alert,
    MyButton,
  },
  props: {
    currentStepData: {
      type: Object,
      default: () => {
        return {
          show: false,
          heading: '',
          text: '',
          expanded_text: '',
          expanded_text_toggle: '',
          policy_text: '',
          policy_link_text: '',
          sourcecode: '',
          privacyPolicyUrl: '',
          dataLayer: [],
        };
      }
    }
  },
  data() {
    return {
      newsletterEmail: '',
      invalidEmail: false,
      showContent: false,
    };
  },
  mounted() {
    console.log('mounted');
    this.newsletterEmail = this.email;
  },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
    ...mapActions({
      addNewsletter: 'newsletterModule/addNewsletter',
      sendWidgetClickEvent: 'newsletterModule/sendWidgetClickEvent',
    }),
    toggleContent() {
      this.showContent = !this.showContent;

      if (this.showContent) {
        this.sendWidgetClickEvent();
      }
    },
    doAction() {
      if (!this.success) {
        if (!this.validateEmail(this.newsletterEmail)) {
          this.invalidEmail = true;
          return;
        }
        this.invalidEmail = false;
        this.addNewsletter({
          email: this.newsletterEmail,
          sourceCode: this.currentStepData.sourcecode
        });
      }
    },
    validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },
  computed: {
    ...mapState({
      email: state => state.email,
      loading: state => state.newsletterModule.newsletterLoading,
      success: state => state.newsletterModule.newsletterSuccess,
      error: state => state.newsletterModule.newsletterError
    }),
    buttonText() {
      return this.t('Newsletterv2', 'button');
    }
  }
};
</script>

<style scoped lang="scss">
</style>
