<template>
  <MyButton v-on:click="goNext" :class="styling" :buttonText="buttonText" :id="id" :disabled="disabled"></MyButton>
</template>

<script>
  import MyButton from "../Button";
  import {mapActions, mapGetters} from 'vuex';
  import { scrollToTop } from '../../store/helpers';

  export default {
    components: {MyButton},
    props: {
      buttonText: {
        type: String,
        default: ''
      },
      styling: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters([
        'currentStepData',
      ]),
      ...mapGetters('extensionModule',[
        'getIndex',
      ]),
    },
    methods: {
      ...mapActions({
        updateUrl: 'updateUrl',
        next: 'extensionModule/next',
      }),
      goNext() {
        scrollToTop();
        this.updateUrl(this.getIndex + 1);

        setTimeout(() => {
          this.next();
        }, 400);
      }
    },
  }
</script>

<style scoped>

</style>
