<template>
  <div>
    <div v-if="loading" class="my-4">
      <div class="sk-circle mb-4">
        <div class="sk-circle1 sk-child" :style="cssVariable"></div>
        <div class="sk-circle2 sk-child" :style="cssVariable"></div>
        <div class="sk-circle3 sk-child" :style="cssVariable"></div>
        <div class="sk-circle4 sk-child" :style="cssVariable"></div>
        <div class="sk-circle5 sk-child" :style="cssVariable"></div>
        <div class="sk-circle6 sk-child" :style="cssVariable"></div>
        <div class="sk-circle7 sk-child" :style="cssVariable"></div>
        <div class="sk-circle8 sk-child" :style="cssVariable"></div>
        <div class="sk-circle9 sk-child" :style="cssVariable"></div>
        <div class="sk-circle10 sk-child" :style="cssVariable"></div>
        <div class="sk-circle11 sk-child" :style="cssVariable"></div>
        <div class="sk-circle12 sk-child" :style="cssVariable"></div>
      </div>
    </div>
    <div class="flex flex-wrap justify-center items-center" v-else>
      <Product
        class="w-1/2 lg:w-4/12 px-4"
        :product="product"
        :moreshop-query-param="moreshopQueryParam"
        v-bind:key="index"
        v-for="(product, index) in products"
      />
    </div>
  </div>
</template>

<script>
import Product from './componentsV2/Product';
import { translate } from './store/helpers';

export default {
  props: {
    productCode: {
      type: String,
      default: null
    },
    moreshopQueryParam: {
      type: String,
      default: '',
    }
  },
  components: { Product },
  data() {
    return {
      products: [],
      loading: true,
      primaryColor: '#97BE0E'
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    fetchProducts() {
      window.axios
        .get('/wp/wp-admin/admin-ajax.php?action=get_products&productCode=' + this.productCode)
        .then(response => {
          this.products = response.data.products;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    t(component, key) {
      return translate(component, key);
    }
  },
  computed: {
    cssVariable() {
      return {
        '--primary-color': this.primaryColor
      };
    }
  }
};
</script>

<style scoped>
.sk-child:before {
  background-color: var(--primary-color);
}
</style>
