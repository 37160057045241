import Vue from 'vue';
import store from '../apps/saveflow/store';
import storeV2 from '../apps/saveflowV2/store';
import SaveFlow from '../apps/saveflow/SaveFlow';
import SaveFlowV2 from '../apps/saveflowV2/SaveFlow';
import NewsletterV2 from '../apps/saveflowV2/Newsletter.vue';
import Moreshop from '../apps/saveflow/Moreshop';
import Newsletter from '../apps/saveflow/Newsletter';

Vue.config.productionTip = false;
Vue.config.devtools = true;

export default {
  init() {
    if (document.getElementById('saveFlow') !== null) {
      new Vue({
        el: '#saveFlow',
        components: {
          SaveFlow,
        },
        store,
      });
    }
    if (document.getElementById('saveFlowV2') !== null) {
      new Vue({
        el: '#saveFlowV2',
        components: {
          SaveFlowV2,
        },
        store: storeV2,
      });
    }
    if (document.getElementById('moreShop') !== null) {
      new Vue({
        el: '#moreShop',
        components: {
          Moreshop,
        },
      });
    }
    if (document.getElementById('newsletter') !== null) {
      new Vue({
        el: '#newsletter',
        components: {
          Newsletter,
        },
        store
      });
    }
    if (document.getElementById('newsletterV2') !== null) {
      new Vue({
        el: '#newsletterV2',
        components: {
          NewsletterV2,
        },
        storeV2
      });
    }
  }
}
