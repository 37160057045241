<template>
  <div class="px-4 md:px-0">
    <transition name="slide-fade"  mode="out-in">
      <component :is="currentComponent"></component>
    </transition>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from 'vuex';
import ExtensionOffers from './extension-steps/ExtensionOffers';
import ExtensionCheckout from './extension-steps/ExtensionCheckout';
import NextStepButton from './NextStepButton';
import { translate } from '../store/helpers';

export default {
  components: {
    NextStepButton,
    ExtensionCheckout,
    ExtensionOffers,
  },
  mounted() {
    this.fetchExtensionV2();
    this.init();

    if (this.queryParams.path !== 0) {
      this.setStep(this.queryParams.path);
    }

    // preselect offer
    if (this.currentStep === 'checkout' && this.selectedOffer === null) {
      this.$store.dispatch(
        `extensionModule/setSelectedOffer`,
        this.offers[0],
      );
    }
  },
  data() {
    return {};
  },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
    ...mapActions({
      init: 'extensionModule/init',
      fetchExtensionV2: 'fetchExtension',
      prev: 'extensionModule/prev',
    }),
    ...mapMutations({
      setStep: 'extensionModule/setStep',
    }),
  },
  computed: {
    ...mapState('extensionModule', ['extension', 'selectedOffer']),
    offers() {
      if (!this.extension?.offers) {
        return [];
      }

      return this.extension.offers.concat([{
        id: -1,
        rebateLabelV2: this.t('OffersV2', 'cancel_offer'),
      }]);
    },
    currentComponent() {
      let component;
      switch (this.currentStep) {
        case 'offers':
          component = ExtensionOffers;
          break;
        case 'checkout':
          component = ExtensionCheckout;
          break;
      }
      return component;
    },
    ...mapGetters('extensionModule', ['currentStep', 'extension']),
    ...mapGetters(['queryParams']),
  },
};
</script>

<style scoped>
</style>
