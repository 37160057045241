<template>
  <div class="px-4 text-center mt-10">
    <h3 class="font-bold mb-3">{{ t('ConfirmationV2', 'byline') }}</h3>
    <p class="mb-4">
      {{ t('ConfirmationV2', 'content') }}
    </p>
    <MyButton
      class="block mt-4"
      :id="this.$options.name + '-cancel'"
      :loading="loading"
      :buttonText="t('ConfirmationV2', 'button')"
      v-on:click="cancelSubscription"
      v-if="!subscriptionCancelledError"
    ></MyButton>
    <p v-if="subscriptionCancelledError" class="text-red-700 text-center mt-4">
      {{ t('ConfirmationV2', 'error') }}
    </p>
  </div>
</template>

<script>
import MyButton from './Button';
import { translate } from '../store/helpers';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'confirmation-component',
  mounted() {
    this.fetchIsTest();
    this.fetchSubNr();
  },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
    ...mapActions({
      cancelSubscription: 'confirmationModule/cancelSubscription',
      fetchIsTest: 'confirmationModule/fetchIsTest',
      fetchSubNr: 'confirmationModule/fetchSubNr'
    })
  },
  computed: {
    ...mapState({
      loading: state => state.confirmationModule.subscriptionCancelLoading,
      subscriptionCancelledError: state => state.confirmationModule.subscriptionCancelledError,
    }),
  },
  components: {
    MyButton
  }
};
</script>

<style scoped></style>
