<template>
  <div class="mx-auto flex flex-col justify-between pb-8">
    <div v-if="subscriptionLoaded">
      <HeaderV2></HeaderV2>

      <div :class="wrapperStyling">
        <CurrentStepV2></CurrentStepV2>
      </div>
    </div>

    <LoadingView v-else :message="'Hämtar kundinfo'"></LoadingView>

    <div class="md:max-w-2x md:mx-auto md:mt-12" v-if="isTest">
      <i class="fas fa-user-secret"></i>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HeaderV2 from './componentsV2/Header';
import LoadingView from './componentsV2/LoadingView';
import CurrentStepV2 from './componentsV2/CurrentStep';
import { gtm } from './utils/gtm';

export default {
  name: 'save-flow',
  components: {
    CurrentStepV2,
    LoadingView,
    HeaderV2,
  },
  mounted() {
    this.fetchActivePaymentMethod();
    this.fetchIsTest();
    this.fetchTestZip();
    this.fetchTestZip();
    this.fetchSteps();
    this.fetchSubscription();
    this.fetchSteps();
    this.fetchMarket();
    this.fetchPrimaryColor();
    this.fetchSecondaryColor();
    this.fetchLogoUrl();
    this.fetchEmail();
    this.fetchCancelUrl();
    this.fetchBaseUrl();
    this.fetchQueryParams();
    this.updateStepsFromQueryParam();
    gtm.pushNewSection(this.currentStepData);
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions([
      'fetchTestZip',
      'fetchIsTest',
      'fetchSteps',
      'fetchSubscription',
      'fetchActivePaymentMethod',
      'fetchMarket',
      'fetchPrimaryColor',
      'fetchSecondaryColor',
      'fetchLogoUrl',
      'fetchEmail',
      'fetchCancelUrl',
      'fetchQueryParams',
      'updateStepsFromQueryParam',
      'fetchBaseUrl',
    ])
  },
  computed: {
    ...mapGetters(['subscriptionLoaded', 'currentStepData', 'isTest', 'currentStep']),
    wrapperStyling() {
      return 'md:max-w-800 md:mx-auto md:mt-12';
    }
  }
};
</script>

<style lang="scss">
</style>
