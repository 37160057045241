<template>
  <div class="px-4 md:px-0">
    <div class="text-center pt-16">
      <span class="badge pt-1.5">
        <i class="far fa-check"></i>
      </span>
    </div>

    <h3 class="mb-0 mt-5 text-center">{{ byline }}</h3>

    <div class="card card--transparent card--no-shadow" v-if="customBullets.length">
      <ul class="list-disc">
        <li
          v-bind:key="index"
          v-for="(bullet, index) in customBullets"
          v-html="bullet"
          class="text-xl leading-medium mb-8"
        ></li>
      </ul>
    </div>

    <Newsletter v-if="currentStepData.newsletter.show" :current-step-data="currentStepData.newsletter"></Newsletter>

    <a class="block btn btn--primary-cta w-full"
       :id="this.$options.name + '-back'"
       :href="overviewLink">{{ t('FinishedV2', 'go_back_to_overview') }}</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { translate } from '../store/helpers';
import Newsletter from "./Newsletter";

export default {
  name: 'finished-confirmation',
  mounted() {},
  computed: {
    ...mapGetters(['primaryColor', 'currentStepData']),
    byline() {
      return this.currentStepData.byline ?? '';
    },
    overviewLink() {
      return this.currentStepData.overviewLink ?? '#';
    },
    customBullets() {
      return this.currentStepData.customBullets ?? [];
    }
  },
  components: { Newsletter },
  methods: {
    t(component, key) {
      return translate(component, key);
    }
  }
};
</script>

<style scoped></style>
