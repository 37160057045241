<template>
  <div>
    <div v-for="(offer) in offers" class="card flex mb-6" @click="toggleSelected(offer.id)" v-bind:key="offer.id">
      <div class="pr-6">
        <input
          type="radio"
          v-bind:id="offer.id"
          v-bind:value="offer.id"
          v-model="selected"
        />
      </div>
      <div class="block">
        <label
          class="w-full pb-4 cursor-pointer text-lg leading-medium"
          v-html="offer.rebateLabelV2">
        </label>

        <div class="flex py-4 px-6 bg-bp-lightgray my-6 rounded" v-if="showOfferContent(offer)">
          <div class="w-16" v-if="cover.length">
            <img :src="cover" :alt="offer.niceName" class="transform -rotate-12">
          </div>
          <div class="flex-1">
            <span class="block text-right text-lg font-medium">{{ offer.niceName }}</span>
            <span class="block text-right text-bp-red text-1xl leading-lg header-font font-black">{{ offer.priceDisplay }}</span>
          </div>
        </div>

        <div class="block text-base leading-sm ul-disc-wrapper"
             :class="{'hidden-first-bullet': shouldHideFirstBullet && !offer.hasImportedCheckoutAcceptText}"
             v-html="offer.checkoutAcceptText"
             v-if="showOfferContent(offer)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { translate } from '../store/helpers';
export default {
  data() {
    return {
      selected: {}
    };
  },
  watch: {
    selected(newValue) {
      if (newValue === null) {
        return;
      }

      this.$store.dispatch(
        `${this.storeModule}/setSelectedOffer`,
        this.offers.filter(offer => parseInt(offer.id, 10) === parseInt(newValue,10))[0]
      );
    }
  },
  mounted() {
    this.selected = this?.selectedOffer?.id ?? null;
  },
  props: {
    offers: {
      type: Array,
      default: () => []
    },
    storeModule: {
      type: String,
      default: ''
    },
    compareToCurrentSubscription: {
      type: Boolean,
      default: false
    },
    cover: {
      type: String,
      default: ''
    },
    shouldHideFirstBullet: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['primaryColor', 'secondaryColor']),
    selectedOffer() {
      return this.$store.state[this.storeModule].selectedOffer;
    },
    selectedCampaign() {
      return this.$store.state[this.storeModule].selectedCampaign;
    },
  },
  methods: {
    showOfferContent(offer) {
      return offer.id !== -1;
    },
    t(component, key) {
      return translate(component, key);
    },
    toggleSelected(id) {
      this.selected = id;
    }
  },
};
</script>

<style scoped type="text/scss">
</style>
