<template>
  <div class="mt-10 mb-2 md:mt-0">
      <Checkout
        :offer="selectedOffer"
        :campaign="extension"
        :activePaymentMethod="activePaymentMethod">
      </Checkout>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import Checkout from '../Checkout';
import { translate } from '../../store/helpers';

export default {
  name: 'extension-checkout-component',
  components: { Checkout },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
    ...mapActions({
      setHeaderText: 'setHeaderText'
    }),
  },
  mounted() {
    this.setHeaderText(this.t('HeadingsV2', 'extension_checkout'))
  },
  beforeDestroy() {
    this.setHeaderText(null);
  },
  computed: {
    ...mapGetters([
      'primaryColor',
      'secondaryColor',
      'activePaymentMethod',
    ]),
    ...mapState('extensionModule', [
      'selectedOffer',
      'extension',
    ]),
  },
};
</script>

<style scoped>

</style>
